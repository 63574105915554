"use client";

import { useEffect } from "react";
import { useFormik } from "hooks";
import { useSignIn } from "hooks/session";
import { useRouter } from "next/router";
import Login from "./Login";
import Restore from "./Restore";

export default function HomePage() {
  const { loading, onSubmit, isSent } = useSignIn();
  const form = useFormik({ initialValues, onSubmit });

  const router = useRouter();
  const { view } = router.query;

  useEffect(() => {
    const allowedValues = ["login", "restore"];
    
    if (view && allowedValues.includes(view)) {
      form.setValues({ view });
    }
  }, [view]);

  return (
    <>
      <Login form={form} loading={loading} />
      <Restore form={form} disabled={isSent} />
    </>
  );
}

const initialValues = {
  email: "",
  password: "",
  view: "login",
};
